/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, Backdrop, CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { observer, useObserver } from "mobx-react-lite";
import Button from "@mui/material/Button";
import { AppInsightMethods } from "../../AppInsightMethods";
import { useGlobalStore } from "../../state/GlobalStore";
import FontWeightDropdown from "./FontWeightDropdown";
import FonstSizeDropdown from "./FontSizeDropdown";
import FontFamilyDropdown from "./FontFamilyDropdown";
import LetterSpacing from "./LetterSpacing";
import Indentation from "./Indentation";
import LineHeight from "./LineHeight";
import LetterStyle from "./LetterStyle";
import Dropdown from "./Dropdown";
import { apiService } from "../../services/ApiService";
import { ConstantVariables } from "../../Constants";
import AddFontDefault from "../../assets/addFontDefault.svg";
import { ReactComponent as AddFont } from "../../assets/addFont.svg";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  drawer: {
    width: 350,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 350,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const CustomFonts = () => {
  const globalStore = useGlobalStore();
  const pageName = "CustomFonst";
  const appInsights = useAppInsightsContext();
  const [popupId, setPopupId] = useState<any>("");
  const trackComponent = useTrackMetric(appInsights, pageName);
  const [showSpinner, setShowSpinner] = useState(false);

  const [textVal, settextVal] = useState("abcd");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueRadio, setFontValue] = useState("");
  const classes = useStyles();

  const open = Boolean(anchorEl);

  useEffect(() => {
    trackComponent();
    setShowSpinner(true);
    globalStore.setCurrentCustomScreen("customFontSelection");
  }, []);

  useEffect(() => {
    if (globalStore.currentCustomScreen === "customFontSelection") {
      setShowSpinner(true);
      apiService
        .getCustomFontdetails()
        .then((resData: any) => {
          setShowSpinner(false);
          if (resData) {
            globalStore.setCustomFontsList(resData);
            resData.some((el) => el.fontName === globalStore.selectedFont) ===
            true
              ? setFontValue(globalStore.selectedFont)
              : setFontValue("");
          }
        })
        .catch((error: any) => {
          setShowSpinner(false);
          AppInsightMethods.TrackAppInsightsException(
            `Error at add getCustomFontdetails API call, Component: ${pageName}, Error: ${error}`
          );
        });
    }
  }, [globalStore.currentCustomScreen]);

  const openPopup = (fontName, heading) => {
    setPopupId(fontName + heading);
    if (heading === "Heading 1") {
      settextVal(ConstantVariables.heading1Info);
    }
    if (heading === "Heading 2") {
      settextVal(ConstantVariables.heading2Info);
    }
    if (heading === "Heading 3") {
      settextVal(ConstantVariables.heading3Info);
    }
    if (heading === "Body 1") {
      settextVal(ConstantVariables.body1Info);
    }
    if (heading === "Body 2") {
      settextVal(ConstantVariables.body2Info);
    }
    if (heading === "Body 3") {
      settextVal(ConstantVariables.body3Info);
    }
    if (heading === "Body 4") {
      settextVal(ConstantVariables.body4Info);
    }
    if (heading === "Body 5") {
      settextVal(ConstantVariables.body5Info);
    }
    if (heading === "Body 6") {
      settextVal(ConstantVariables.body6Info);
    }
    if (heading === "Body 7") {
      settextVal(ConstantVariables.body7Info);
    }
    if (heading === "Button") {
      settextVal(ConstantVariables.buttonInfo);
    }
    if (heading === "Label") {
      settextVal(ConstantVariables.labelInfo);
    }
  };
  const closePopup = (itemId) => {
    setPopupId("");
  };

  const saveCustomValueApi = (val) => {
    setShowSpinner(true);
    const customFontVal = { ...val };

    customFontVal.fontType = "Custom";
    customFontVal.isSelected = true;
    customFontVal.uploadedBy = globalStore.loginUserEmail;

    apiService
      .addFontDetailsApi(customFontVal)
      .then((res: any) => {
        setShowSpinner(false);
        globalStore.setShowCustomSavePop(true);
        globalStore.setSelectedFont(res.fontName);
        setFontValue("");
        setTimeout(() => {
          globalStore.setShowCustomSavePop(false);
        }, 5000);
      })
      .catch((error: any) => {
        setShowSpinner(false);
        AppInsightMethods.TrackAppInsightsException(
          `Error at add fontdetails API call, Component: ${pageName}, Error: ${error}`
        );
      });
  };

  const getDropdownValue = (fontWeightVal: string, fontStyleVal: string) => {
    if (fontWeightVal === "300" && fontStyleVal === "normal") {
      return "Light";
    }
    if (fontWeightVal === "400" && fontStyleVal === "normal") {
      return "Regular";
    }
    if (fontWeightVal === "350" && fontStyleVal === "normal") {
      return "Semilight";
    }
    if (fontWeightVal === "600" && fontStyleVal === "normal") {
      return "Semibold";
    }
    if (fontWeightVal === "700" && fontStyleVal === "normal") {
      return "Bold";
    }
    if (fontWeightVal === "900" && fontStyleVal === "normal") {
      return "Black";
    }
    if (fontWeightVal === "400" && fontStyleVal === "Italic") {
      return "Italic";
    }
    if (fontWeightVal === "600" && fontStyleVal === "Italic") {
      return "Semibold Italic";
    }
    if (fontWeightVal === "700" && fontStyleVal === "Italic") {
      return "Bold Italic";
    }
    if (fontWeightVal === "350" && fontStyleVal === "Italic") {
      return "Semilight Italic";
    }
    return "Light";
  };

  /* Method to handle tooltip information open */
  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  /* Method to handle tooltip information close */
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const gotoFontFamilySelection = () => {
    globalStore.setCurrentCustomScreen("fontFamilyDropdown");
    globalStore.setFontFamilyTab("", "");
  };

  const backToFontSelection = () => {
    globalStore.setCurrentCustomScreen("customFontSelection");
    globalStore.setFontFamilyTab("", "");
  };

  // font creation with default font style values and selected font family
  const goToFontCreation = () => {
    globalStore.setCurrentCustomScreen("customFontCreation");
    globalStore.setCustomFonCreationVal();
  };

  const handleFontSelection = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if ((event.target as HTMLInputElement).value !== valueRadio) {
      setFontValue((event.target as HTMLInputElement).value);
    } else {
      setFontValue("");
    }
  };

  const handleFontCancel = () => {
    setFontValue(globalStore.selectedFont);
  };

  return useObserver(() => {
    return (
      <Grid className="customFontGrid">
        {globalStore.currentCustomScreen === "customFontSelection" &&
          globalStore.customFontsList.length > 0 && (
            <Grid className="fontSelectionMain">
              <div className="heading">
                <div
                  style={{
                    marginTop: "42px",
                    color: "#8A8A8A",
                    font: "normal normal normal 18px/24px Segoe UI",
                  }}
                >
                  Select Custom Font :
                </div>

                <Button
                  className="addFontButton"
                  onClick={gotoFontFamilySelection}
                >
                  + Add Font
                </Button>
              </div>

              {globalStore.customFontsList.map((item) => (
                <div style={{ width: "96%", marginTop: "20px" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <FormControl
                        key={item.fontName}
                        className="frmCtr"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                      >
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          className="radioGrp"
                          value={valueRadio}
                        >
                          <FormControlLabel
                            className="frmCtrLbl"
                            value={item.fontName}
                            control={
                              <Radio
                                onClick={(event) => handleFontSelection(event)}
                              />
                            }
                            label={item.fontName}
                          />
                        </RadioGroup>
                      </FormControl>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginLeft: "30px",
                        }}
                      >
                        details here
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}

              <div className="FontDropdownButtons">
                <Button className="cancelButton" onClick={handleFontCancel}>
                  Cancel
                </Button>
                <Button
                  className="saveButton"
                  // onClick={() => {
                  // hit api and save selected value in db to reflect in other apps
                  // }}
                >
                  Apply
                </Button>
              </div>
            </Grid>
          )}
        {globalStore.currentCustomScreen === "customFontSelection" &&
          globalStore.customFontsList.length === 0 && (
            <Grid className={"noCustomFont"}>
              <img src={AddFontDefault} alt={"Add font"} />
              <p className="addFontTxt">ADD FONT</p>
              <p className="welcomeTxt">
                Welcome to the Oltiva Healthcare. Create your own font based on
                your branding.
              </p>
              <AddFont
                onClick={gotoFontFamilySelection}
                style={{ cursor: "pointer" }}
              />
            </Grid>
          )}

        <div className="cardMain">
          {globalStore.currentCustomScreen === "fontFamilyDropdown" && (
            <>
              <Dropdown />

              <div className="FontDropdownButtons">
                <Button className="cancelButton" onClick={backToFontSelection}>
                  Back
                </Button>
                <Button
                  className="saveButton"
                  onClick={goToFontCreation}
                  style={{
                    pointerEvents:
                      globalStore.fontFamilyTab.fontFamilyName === ""
                        ? "none"
                        : "all",
                  }}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {globalStore.currentCustomScreen === "customFontCreation" && (
            <>
              <Grid
                className={"fontFamilyText"}
                style={{ fontFamily: globalStore.CustomCardValue.fontName }}
              >
                {globalStore.CustomCardValue.fontName}
              </Grid>

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {globalStore.CustomCardValue?.fontDetails?.map((subItem) => (
                  <div
                    className="editCard"
                    key={globalStore.CustomCardValue.fontName + subItem.heading}
                  >
                    <Card
                      sx={{ minWidth: 275, width: 340 }}
                      className="cardCreateFont"
                    >
                      <CardContent className="cardContentDefaultFont">
                        <Typography
                          className="Heading"
                          style={{
                            font: `${subItem.fontStyle} ${subItem.fontWeight} ${subItem.fontSize}/${subItem.lineHeight} ${subItem.fontFamily}`,
                            textIndent: subItem.textIndent,
                            letterSpacing: subItem.letterSpacing,
                            textTransform: subItem?.textTransform,
                            textDecoration: subItem.textDecoration,
                          }}
                        >
                          {subItem.heading}
                        </Typography>
                        <Typography className="fontSizeWeight">
                          {subItem.fontFamily},{" "}
                          {getDropdownValue(
                            subItem.fontWeight,
                            subItem.fontStyle
                          )}
                        </Typography>
                        <Typography className="lineHeight">
                          {subItem.fontSize}/{subItem.lineHeight}
                        </Typography>
                        <EditIcon
                          className="editIcon"
                          onClick={() =>
                            openPopup(
                              globalStore.CustomCardValue.fontName,
                              subItem.heading
                            )
                          }
                        />
                      </CardContent>
                    </Card>
                    <div
                      className="fontPopup"
                      id={
                        globalStore.CustomCardValue.fontName + subItem.heading
                      }
                      style={{
                        visibility:
                          popupId ===
                          `${globalStore.CustomCardValue.fontName}${subItem.heading}`
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      <Typography
                        aria-owns={
                          open
                            ? globalStore.CustomCardValue.fontName +
                              subItem.heading
                            : undefined
                        }
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      >
                        <InfoOutlinedIcon className={"InfoIcon"} />
                      </Typography>
                      {popupId ===
                        `${globalStore.CustomCardValue.fontName}${subItem.heading}` && (
                        <Popover
                          id={
                            globalStore.CustomCardValue.fontName +
                            subItem.heading
                          }
                          className={classes.popover}
                          classes={{
                            paper: classes.paper,
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <Typography>
                            <Grid
                              className={"hoverToolTipContent"}
                              style={{
                                font: "italic normal 400 12px/16px Segoe UI",
                                color: "#ADADAD",
                              }}
                            >
                              {textVal}
                            </Grid>
                          </Typography>
                        </Popover>
                      )}

                      <div className="PopupFontUpperText">
                        {" "}
                        <Typography
                          className="Heading"
                          style={{
                            font: `${subItem.fontStyle} ${subItem.fontWeight} ${subItem.fontSize}/${subItem.lineHeight} ${subItem.fontFamily}`,
                            textIndent: subItem.textIndent,
                            letterSpacing: subItem.letterSpacing,
                            textTransform: subItem?.textTransform,
                            textDecoration: subItem.textDecoration,
                          }}
                        >
                          {subItem.heading}
                        </Typography>
                        <Typography className="fontSizeWeight">
                          {subItem.fontFamily},{" "}
                          {getDropdownValue(
                            subItem.fontWeight,
                            subItem.fontStyle
                          )}
                        </Typography>
                        <Typography className="lineHeight">
                          {subItem.fontSize}/{subItem.lineHeight}
                        </Typography>
                      </div>

                      <div className="horizontalAlign">
                        <div>
                          <FonstSizeDropdown
                            fontSize={subItem.fontSize}
                            headingName={subItem.heading}
                          />
                        </div>
                        <div>
                          <FontWeightDropdown
                            fontWeight={subItem.fontWeight}
                            fontStyle={subItem.fontStyle}
                            headingName={subItem.heading}
                          />
                        </div>
                      </div>
                      <div className="horizontalAlign">
                        {" "}
                        <div>
                          <LetterSpacing
                            letterSpacing={subItem.letterSpacing}
                            headingName={subItem.heading}
                          />
                        </div>
                        <div>
                          <LineHeight
                            lineHeight={subItem.lineHeight}
                            headingName={subItem.heading}
                          />
                        </div>
                        <div>
                          <Indentation
                            textIndent={subItem.textIndent}
                            headingName={subItem.heading}
                          />
                        </div>
                      </div>
                      <div className="letterStyle">
                        <LetterStyle
                          textTransform={subItem.textTransform}
                          textDecoration={subItem.textDecoration}
                          headingName={subItem.heading}
                        />
                      </div>
                      <div className="popupButtons">
                        <Typography
                          style={{
                            cursor: "pointer",
                            font: "normal normal 600 18px/24px Segoe UI",
                            color: "#8A8A8A",
                          }}
                          onClick={() => {
                            closePopup(
                              globalStore.CustomCardValue.fontName +
                                subItem.heading
                            );
                            globalStore.cancelCustomFontsPopup();
                          }}
                        >
                          Cancel
                        </Typography>
                        <Typography
                          style={{
                            cursor: "pointer",
                            font: "normal normal 600 18px/24px Segoe UI",
                            color: "#4A8409",
                          }}
                          onClick={() => {
                            closePopup(
                              globalStore.CustomCardValue.fontName +
                                subItem.heading
                            );
                            globalStore.saveCustomFontsPopup(
                              globalStore.CustomCardValue
                            );
                          }}
                        >
                          Save
                        </Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {showSpinner && (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 999,
                  }}
                  open={showSpinner}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
            </>
          )}
        </div>
        {globalStore.currentCustomScreen === "customFontCreation" && (
          <div className="FontDropdownButtons">
            <Button
              className="cancelButton"
              onClick={() =>
                globalStore.setCurrentCustomScreen("fontFamilyDropdown")
              }
            >
              Cancel
            </Button>
            <Button
              className="saveButton"
              onClick={() => {
                saveCustomValueApi(globalStore.CustomCardValue);
              }}
            >
              Save & Apply
            </Button>
          </div>
        )}
      </Grid>
    );
  });
};
export default observer(CustomFonts);
