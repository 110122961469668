/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unneeded-ternary */
import {
  IDeleteRelationSettings,
  ILobbyDelMessage,
  IDefaultCardValue,
} from "../state/GlobalStateType";
import {
  AzureCommunicationUserToken,
  IAcsParticipants,
  IAddConfigurationMsg,
  IAddRelation,
  IAddReminderList,
  IDeleteMaterial,
  IDelReminderList,
  IEditReminderList,
  IUpdateState,
  IuploadImage,
  IUpdateSystemSettings,
  IUpdateAudioVideoSettings,
  IMeetingSettings,
  IAddAppointmentMsg,
  IDelAppointmentMsg,
  IUpdateAppointmentMsg,
  IAddNotificationTemplate,
  IUpdateNotificationTemplate,
  IAddTestUser,
  IDelTestUser,
  IAddTestAppointment,
  IDelTestAppointment,
  IUpdateLanguage,
  IAddLanguage,
  IDelLanguage,
  IPostUserRole,
  IThemeColors,
  IFontDetails,
  IGetMeetingLogs,
  IDeleteVideo,
} from "../state/StoreTypes";
import { authApiService } from "./AuthenticationApiService";
import configData from "../config.json";

interface getAcsTokenResponse {
  value: AzureCommunicationUserToken;
}

export const apiService = {
  getAcsToken: async (): Promise<getAcsTokenResponse> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/token`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json();
  },
  getAcsTokenPostMethod: async (
    acsParticipants: IAcsParticipants
  ): Promise<getAcsTokenResponse> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url = `${urlDomain}/token`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(acsParticipants),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },
  newMaterial: async (newResource: any): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-resource`;
    const response = await fetch(url1, {
      method: "POST",
      body: newResource,
      headers: {
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },

  uploadImage: async (image: IuploadImage): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/upload-file`;

    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(image),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return "Success";
  },
  deleteMaterial: async (removeDelete: IDeleteMaterial): Promise<any> => {
    const bToken = "";
    const urlDomain = await configData.API_URL;
    const response = await fetch(
      `${urlDomain}/remove-material/${removeDelete.Id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${bToken}`,
        },
        body: JSON.stringify(removeDelete),
      }
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  getMaterialList: async (): Promise<any> => {
    const bToken = "";
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-all-materials`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },

  editMaterial: async (eMAterial: any): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/edit-material`, {
      method: "PUT",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: eMAterial,
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },

  getLobbyMessage: async (): Promise<any> => {
    const bToken = "";
    const urlDomain = await configData.API_URL;
    const response = await fetch(
      `${urlDomain}/get-all-configuration-messages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bToken}`,
        },
      }
    );

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },

  postConfigurationMsg: async (newMsg: IAddConfigurationMsg): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-configuration-message`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(newMsg),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },

  editConfigurationMsg: async (editMsg: any): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/update-configuration-message`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(editMsg),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },

  delConfigurationMsg: async (delMsg: ILobbyDelMessage): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/remove-configuration-message`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(delMsg),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  getAllSystemSettings: async (): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-all-system-settings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
  updateSystemSettings: async (
    systemSettings: IUpdateSystemSettings
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/update-system-settings`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(systemSettings),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },

  updateState: async (eMAterial: IUpdateState): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/update-state`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(eMAterial),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  getRelationshipSettings: async (): Promise<any> => {
    const bToken = "";
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-relationship-settings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },

  addRelationsettings: async (newRelatn: IAddRelation): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-relationship-setting`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(newRelatn),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },
  delRelationsettings: async (
    delRelatn: IDeleteRelationSettings
  ): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/remove-relationship-setting`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(delRelatn),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  editRelationshipSettings: async (editRelatnS: any): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/update-relationship-setting`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(editRelatnS),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  getReminder: async (): Promise<any> => {
    const bToken = "";
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-all-reminders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
  addReminder: async (newRelatn: IAddReminderList): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-reminder`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(newRelatn),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },
  updateReminder: async (updateRem: IEditReminderList): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/update-reminder`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(updateRem),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },
  delReminder: async (delReminder: IDelReminderList): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/remove-reminder`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(delReminder),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  getAudioVideoSettings: async (): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/fetchAudioVideoSettings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
  updateAudioVideoSettings: async (
    settings: IUpdateAudioVideoSettings
  ): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/updateAudioVideoSettings`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(settings),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  getMeetingSettings: async (): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-all-meeting-settings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
  updateMeetingSettings: async (
    settings: IMeetingSettings
  ): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/update-meeting-setting`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(settings),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  getAppointmentMsg: async (): Promise<any> => {
    const bToken = "";
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-all-appointment-messages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
  addAppointmentMsg: async (
    newAppointment: IAddAppointmentMsg
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-appointment-message`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(newAppointment),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },
  updateAppointmentMsg: async (
    updateAppointmentMsg: IUpdateAppointmentMsg
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/update-appointment-message`;
    const response = await fetch(url1, {
      method: "PUT",
      body: JSON.stringify(updateAppointmentMsg),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },
  delAppointmentMsg: async (
    delAppointmentMsg: IDelAppointmentMsg
  ): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/remove-appointment-message`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(delAppointmentMsg),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  getNotification: async (): Promise<any> => {
    const bToken = "";
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/fetch-notification-templates`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
  addNotificationTemplate: async (
    newAppointment: IAddNotificationTemplate
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-notification-template`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(newAppointment),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },
  updateNotification: async (
    updateBotificationObj: IUpdateNotificationTemplate
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/update-notification-template`;
    const response = await fetch(url1, {
      method: "PUT",
      body: JSON.stringify(updateBotificationObj),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },
  fetchTestUser: async (): Promise<any> => {
    const bToken = "";
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/fetch-testusers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No users found";
      if (errorMessage === "No users found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
  addTestUser: async (newTestUser: IAddTestUser): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-test-user`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(newTestUser),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },

  fetchTestAppointment: async (
    _startDate: string,
    _endDate: string
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/fetch-testappointment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify({
        startDate: _startDate,
        endDate: _endDate,
      }),
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "Meetings not found";
      if (errorMessage === "Meetings not found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
  delTestUser: async (delTestUserObj: IDelTestUser): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/delete-testuser`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(delTestUserObj),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  delTestUserSel: async (delTestUserObj: string[]): Promise<string> => {
    const reqObj: IDelTestUser = {
      userId: delTestUserObj,
    };
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/delete-testuser`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(reqObj),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  delTestAppoint: async (
    delTestUserObj: IDelTestAppointment
  ): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/testappointment`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(delTestUserObj),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  delTestAppointSel: async (delTestUserObj: string[]): Promise<string> => {
    const reqObj: IDelTestAppointment = {
      televisitId: delTestUserObj,
    };
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/testappointment`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(reqObj),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  addTestAppointment: async (
    appointment: IAddTestAppointment
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/testappointment`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(appointment),
    });
    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message
        ? message
        : "Something went wrong. Please try again.";
      throw new Error(errorMessage);
    }
    return response.json();
  },
  fetchProvider: async (groupId: string): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-providerlist/${groupId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
  getLanguages: async (): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-all-languages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
  addLanguage: async (newRelatn: IAddLanguage): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-language`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(newRelatn),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },
  updateLanguage: async (updateLanguageObj: IUpdateLanguage): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/update-language`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(updateLanguageObj),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },
  delLanguage: async (delTestUserObj: IDelLanguage): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/remove-language`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify(delTestUserObj),
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  getUserRole: async (userRole: IPostUserRole): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/get-user-role`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(userRole),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },

  getAppointments: async (
    _startDate: string,
    _endDate: string,
    _groupId: string,
    _userId: string
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/meeting-list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify({
        startDate: _startDate,
        endDate: _endDate,
        groupId: _groupId,
        userId: _userId,
      }),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },

  getAppointmentsByFilter: async (
    _timeRange: string[],
    _groupId: string,
    _userId: string
  ): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/meeting-list-by-timeRange`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: JSON.stringify({
        timeRange: _timeRange,
        groupId: _groupId,
        userId: _userId,
      }),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },

  addCustomLayout: async (layoutData: any): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/add-customlayout`, {
      method: "POST",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: layoutData,
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },

  addCustomTheme: async (themeData: IThemeColors): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-themedetails`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(themeData),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  getCustomLayout: async (): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-customlayout`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
  getCustomTheme: async (): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-themedetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },

  getSelectedCustomTheme: async (): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-selected-theme`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },

  addCustomizationLogo: async (newResource: any): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-customizelogo`;
    const response = await fetch(url1, {
      method: "POST",
      body: newResource,
      headers: {
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },

  saveDefaultFontDetailsApi: async (fontName: string): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/update-defaultfontdetails?fontName=${fontName}`;
    const response = await fetch(url1, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },

  getMeetingLogs: async (getMeetingLog: IGetMeetingLogs): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(
      `${urlDomain}/get-meetinglogs?meetingId=${getMeetingLog.meetingId}&timespan=${getMeetingLog.timespan}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${bToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },

  getRelaxatiobVideosList: async (): Promise<any> => {
    const bToken = "";
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-videos`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },

  newVideo: async (newVideo: any): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-video`;
    const response = await fetch(url1, {
      method: "POST",
      body: newVideo,
      headers: {
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "Success";
  },

  updateVideoState: async (eVideo: any): Promise<string> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/update-video-state`, {
      method: "PUT",
      headers: {
        // Accept: "application/json",
        // "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
      body: eVideo,
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },

  getSelectedFontDetails: async () => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-selectedfontdetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  },

  deleteVideo: async (removeDelete: IDeleteVideo): Promise<any> => {
    const bToken = "";
    const urlDomain = await configData.API_URL;
    const response = await fetch(
      `${urlDomain}/delete-video/${removeDelete.Id}`,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${bToken}`,
        },
        body: JSON.stringify(removeDelete),
      }
    );
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return "success";
  },
  addFontDetailsApi: async (newCustomFont: IDefaultCardValue): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const url1 = `${urlDomain}/add-fontdetails`;
    const response = await fetch(url1, {
      method: "POST",
      body: JSON.stringify(newCustomFont),
      headers: {
        Authorization: `Bearer ${bToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json();
  },

  getCustomFontdetails: async (): Promise<any> => {
    const bToken = await authApiService.getAccessToken();
    const urlDomain = await configData.API_URL;
    const response = await fetch(`${urlDomain}/get-customfontdetails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bToken}`,
      },
    });

    if (!response.ok) {
      const { message } = await response.json();
      const errorMessage = message ? message : "No resources found";
      if (errorMessage === "No resources found") {
        return [];
      }
      throw new Error(errorMessage);
    }
    return response.json();
  },
};
