/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from "react";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { useObserver } from "mobx-react-lite";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGlobalStore } from "../../state/GlobalStore";
import { apiService } from "../../services/ApiService";

import { AppInsightMethods } from "../../AppInsightMethods";

const fontFamily = [
  {
    fontFamilyName: "Arial",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Brush Script MT",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Courier New",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Georgia",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Garamond",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Geneva",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Roboto",
    fontFamilyType: [""],
  },

  {
    fontFamilyName: "Sans-serif",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Tahoma",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Trebuchet MS",
    fontFamilyType: [""],
  },
  {
    fontFamilyName: "Times New Roman",
    fontFamilyType: [""],
  },

  {
    fontFamilyName: "Verdana",
    fontFamilyType: [""],
  },
];

export default function Dropdown() {
  const globalStore = useGlobalStore();
  const pageName = "FontFamilyDropdown";
  const refClassText = "fontFamilyRefClass";

  const [isOpen, setIsOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const wrapperRef = useRef<any>();

  /* On Change handler when font family dropdown is clicked */
  const onTabChangeHandler = (
    event: unknown,
    valueFontFamily: string,
    valueFontType: string
  ) => {
    try {
      // setShowSpinner(true);
      // todo: make api call and if font family is available in custom font show poup
      // apiService
      //   .getCustomFontFamilyList()
      //   .then((resData: any) => {
      //     setShowSpinner(false);
      //     if (resData) {
      //       // if valueFontFamily exists in resData--> show popup
      //     }
      //   })
      //   .catch((error: any) => {
      //     setShowSpinner(false);
      //     AppInsightMethods.TrackAppInsightsException(
      //       `Error at get fontdetails API call, Component: ${pageName}, Error: ${error}`
      //     );
      //   });

      globalStore.setFontFamilyTab(valueFontFamily, valueFontType);

      AppInsightMethods.trackAppInsightsEvent(
        pageName,
        "Information",
        `Font family Change event, selected Font family is ${valueFontFamily}`,
        globalStore.loginUserEmail,
        window.location.href,
        ""
      );
    } catch (error) {
      AppInsightMethods.TrackAppInsightsException(
        `Error at onTabChangeHandler, Component: ${pageName}, Error: ${error}`
      );
    }
  };

  return useObserver(() => {
    return (
      <Grid className={"fontFamilyDropdown"} onClick={() => setIsOpen(!isOpen)}>
        {showSpinner && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 999,
            }}
            open={showSpinner}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Grid
          className={"fontFamilyHeading"}
          style={{ fontFamily: globalStore.fontFamilyTab.fontFamilyName }}
        >
          {globalStore.fontFamilyTab.fontFamilyName !== ""
            ? globalStore.fontFamilyTab.fontFamilyName
            : "Select Font Family"}{" "}
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Grid>
        {isOpen && (
          <Grid className={"fontFamilyMenuitems"} ref={wrapperRef}>
            {fontFamily.map((item) => (
              <>
                {item.fontFamilyType.map((subItem) => (
                  <Grid
                    className={`fontFamilyitem ${refClassText} ${
                      globalStore.fontFamilyTab.fontFamilyName +
                        globalStore.fontFamilyTab.fontFamilyType ===
                      item.fontFamilyName + subItem
                        ? "selected"
                        : ""
                    }`}
                    style={{ fontFamily: item.fontFamilyName }}
                    onClick={(e) =>
                      onTabChangeHandler(e, item.fontFamilyName, subItem)
                    }
                  >
                    {item.fontFamilyName}
                    <span className="fontFamilySubitem">{subItem}</span>
                  </Grid>
                ))}
              </>
            ))}
          </Grid>
        )}

        {/* <UpdateFontFamily
          callBackUpdateFontFamilyt={updateFontFamily}
          popup={popupUpdateFont}
          closeCallback={() => closeCallbackHandler()}
        /> */}
      </Grid>
    );
  });
}
